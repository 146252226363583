import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import _ from 'lodash'
import Header from './components/Header'
import Content from './components/Content'
import AnimatedBackground from './components/AnimatedBackground'
import useMediaQuery from './useMediaQuery'

function App() {
    const [theme, setTheme] = useState('light')
    const [searchTerm, setSearchTerm] = useState('')
    const [activeSection, setActiveSection] = useState(null)
    const [isScrolled, setIsScrolled] = useState(false)
    const [scrollY, setScrollY] = useState(0)
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)
    const [visibleHeight, setVisibleHeight] = useState(window.innerHeight)
    const appRef = useRef(null)
    const contentRef = useRef(null)

    const scrollThreshold = 200
    const isMdScreen = useMediaQuery('(min-width: 768px)')
    const scrollTop = useMemo(() => (isMdScreen ? 500 : 475), [isMdScreen])

    useEffect(() => {
        document.documentElement.classList.toggle('dark', theme === 'dark')
    }, [theme])

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light')
    }

    const handleEndScroll = useMemo(
        () =>
            _.debounce(() => {
                if (isKeyboardVisible) return
                const scrollY = window.pageYOffset
                if (scrollY >= scrollThreshold && scrollY < scrollTop) {
                    window.scrollTo({ top: scrollTop, behavior: 'smooth' })
                } else if (scrollY < scrollThreshold) {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }
            }, 400),
        [scrollTop, isKeyboardVisible]
    )

    const handleScroll = useCallback(() => {
        // if (isKeyboardVisible) return
        const scrollPosition = window.pageYOffset
        setScrollY(scrollPosition)
        if (isScrolled && scrollPosition <= scrollThreshold) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        setIsScrolled(scrollPosition > scrollThreshold)
        handleEndScroll()
    }, [isScrolled, handleEndScroll, isKeyboardVisible])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [handleScroll])

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        setTheme(mediaQuery.matches ? 'dark' : 'light')
        const handleChange = (e) => {
            setTheme(e.matches ? 'dark' : 'light')
        }
        mediaQuery.addListener(handleChange)
        return () => mediaQuery.removeListener(handleChange)
    }, [])

    const [isPrinting, setIsPrinting] = useState(false)

    useEffect(() => {
        const mediaQueryList = window.matchMedia('print')
        const listener = (mql) => {
            setIsPrinting(mql.matches)
        }
        mediaQueryList.addListener(listener)
        return () => mediaQueryList.removeListener(listener)
    }, [])

    useEffect(() => {
        if (searchTerm.length > 2 && contentRef.current && !isKeyboardVisible) {
            const scrollPosition = scrollTop
            setTimeout(
                () =>
                    contentRef.current.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth',
                    }),
                200
            )
        }
    }, [searchTerm, scrollTop, isKeyboardVisible])

    useEffect(() => {
        const detectKeyboard = () => {
            const newVisibleHeight = window.visualViewport.height
            const isKeyboard = window.innerHeight > newVisibleHeight
            setIsKeyboardVisible(isKeyboard)
            setVisibleHeight(newVisibleHeight)

            if (appRef.current) {
                appRef.current.style.height = `${newVisibleHeight}px`
            }
        }

        window.visualViewport.addEventListener('resize', detectKeyboard)
        window.visualViewport.addEventListener('scroll', detectKeyboard)

        return () => {
            window.visualViewport.removeEventListener('resize', detectKeyboard)
            window.visualViewport.removeEventListener('scroll', detectKeyboard)
        }
    }, [])

    return (
        <div className={`flex flex-col min-h-screen w-full ${isKeyboardVisible && 'min-h-1/2'}`}>
            <Header
                isKeyboardVisible={isKeyboardVisible}
                onChatted={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                toggleTheme={toggleTheme}
                setSearchTerm={(term) => {
                    if (term.length === 1) {
                        window.scrollTo({ top: scrollTop, behavior: 'smooth' })
                    } else if (term.length === 0) {
                        setSearchTerm(term)
                    } else {
                        setTimeout(() => setSearchTerm(term), 500)
                    }
                }}
                isScrolled={isScrolled}
            />
            <div
                className={`flex flex-1 overflow-hidden transition-all ${isScrolled ? 'print:mt-16' : 'mt-screen'}`}
                style={{
                    marginTop: isScrolled && (isPrinting ? 10 : isMdScreen ? '560px' : '600px'),
                }}
            >
                <div className='mx-auto flex flex-1 overflow-hidden max-w-7xl w-full'>
                    <Content
                        isPrinting={isPrinting}
                        className='flex-1 overflow-y-auto mx-4'
                        searchTerm={searchTerm}
                        contentRef={contentRef}
                        setActiveSection={setActiveSection}
                    />
                </div>
            </div>
            {!isKeyboardVisible && (
                <div
                    className={`fixed top-36 md:top-32 left-1/2 -ml-4 text-gray-800 dark:text-gray-300 animate-bounce`}
                    style={{
                        opacity: isScrolled ? Math.max(0, scrollTop - scrollY) / 100 : 0,
                    }}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='size-9'
                    >
                        <path strokeLinecap='round' strokeLinejoin='round' d='m4.5 18.75 7.5-7.5 7.5 7.5' />
                        <path strokeLinecap='round' strokeLinejoin='round' d='m4.5 12.75 7.5-7.5 7.5 7.5' />
                    </svg>
                </div>
            )}
            <AnimatedBackground scrollY={0} theme={theme} />
        </div>
    )
}

export default App

// import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
// import _ from 'lodash'
// import Header from './components/Header'
// import Sidebar from './components/Sidebar'
// import Content from './components/Content'
// import AnimatedBackground from './components/AnimatedBackground'
// import useMediaQuery from './useMediaQuery'

// function App() {
//     const [theme, setTheme] = useState('light')
//     const [searchTerm, setSearchTerm] = useState('')
//     const [activeSection, setActiveSection] = useState(null)
//     const [isScrolled, setIsScrolled] = useState(false)
//     const [scrollY, setScrollY] = useState(0)
//     const contentRef = useRef(null)

//     const scrollThreshold = 200
//     const isMdScreen = useMediaQuery('(min-width: 768px)')
//     const scrollTop = useMemo(() => (isMdScreen ? 500 : 475), [isMdScreen])

//     // const scrollToSection = (sectionId) => {
//     //     const sectionElement = document.getElementById(sectionId)
//     //     if (sectionElement && contentRef.current) {
//     //         const topOffset = sectionElement.offsetTop - contentRef.current.offsetTop - 64 // Adjusted for compact header height
//     //         contentRef.current.scrollTo({
//     //             top: topOffset,
//     //             behavior: 'smooth',
//     //         })
//     //     }
//     // }

//     useEffect(() => {
//         document.documentElement.classList.toggle('dark', theme === 'dark')
//     }, [theme])

//     const toggleTheme = () => {
//         setTheme(theme === 'light' ? 'dark' : 'light')
//     }

//     const handleEndScroll = useMemo(
//         () =>
//             _.debounce(() => {
//                 const scrollY = window.pageYOffset
//                 if (scrollY >= scrollThreshold && scrollY < scrollTop) {
//                     window.scrollTo({ top: scrollTop, behavior: 'smooth' })
//                 }
//             }, 500),
//         [scrollTop]
//     )

//     const handleScroll = useCallback(() => {
//         const scrollPosition = window.pageYOffset
//         setScrollY(scrollPosition)
//         // DEBUG
//         // console.log('Scroll', scrollPosition)
//         if (isScrolled && scrollPosition <= scrollThreshold) {
//             window.scrollTo({ top: 0, behavior: 'smooth' })
//         }
//         setIsScrolled(scrollPosition > scrollThreshold)
//         handleEndScroll()
//     }, [isScrolled, handleEndScroll])

//     useEffect(() => {
//         window.addEventListener('scroll', handleScroll)
//         return () => window.removeEventListener('scroll', handleScroll)
//     }, [handleScroll])

//     useEffect(() => {
//         const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
//         setTheme(mediaQuery.matches ? 'dark' : 'light')
//         const handleChange = (e) => {
//             setTheme(e.matches ? 'dark' : 'light')
//         }
//         mediaQuery.addListener(handleChange)
//         return () => mediaQuery.removeListener(handleChange)
//     }, [])

//     const [isPrinting, setIsPrinting] = useState(false)

//     useEffect(() => {
//         const mediaQueryList = window.matchMedia('print')
//         const listener = (mql) => {
//             setIsPrinting(mql.matches)
//         }
//         mediaQueryList.addListener(listener)
//         return () => mediaQueryList.removeListener(listener)
//     }, [])

//     useEffect(() => {
//         if (searchTerm.length > 2 && contentRef.current) {
//             const scrollPosition = scrollTop
//             setTimeout(
//                 () =>
//                     contentRef.current.scrollTo({
//                         top: scrollPosition,
//                         behavior: 'smooth',
//                     }),
//                 200
//             )
//         }
//     }, [searchTerm, scrollTop])

//     return (
//         <div className='flex flex-col min-h-screen w-full'>
//             <Header
//                 toggleTheme={toggleTheme}
//                 setSearchTerm={(term) => {
//                     if (term.length === 1) {
//                         window.scrollTo({ top: scrollTop, behavior: 'smooth' })
//                     } else if (term.length == 0) {
//                         setSearchTerm(term)
//                     } else {
//                         setTimeout(() => setSearchTerm(term), 500)
//                     }
//                 }}
//                 isScrolled={isScrolled}
//             />
//             <div
//                 className={`flex flex-1 overflow-hidden transition-all ${isScrolled ? 'print:mt-16' : 'mt-screen'}`}
//                 style={{
//                     marginTop: isScrolled && (isPrinting ? 10 : isMdScreen ? '560px' : '600px'),
//                 }}
//             >
//                 <div className='mx-auto flex flex-1 overflow-hidden max-w-7xl w-full'>
//                     {/* <Sidebar
//                         className='w-64 flex-shrink-0 overflow-y-auto'
//                         scrollToSection={scrollToSection}
//                         activeSection={activeSection}
//                     /> */}

//                     <Content
//                         isPrinting={isPrinting}
//                         className='flex-1 overflow-y-auto mx-4'
//                         searchTerm={searchTerm}
//                         contentRef={contentRef}
//                         setActiveSection={setActiveSection}
//                     />
//                 </div>
//             </div>
//             <div
//                 className={`fixed top-36 md:top-32 left-1/2 -translate-x-1/2 text-gray-800 dark:text-gray-300 animate-bounce`}
//                 style={{
//                     opacity: isScrolled ? Math.max(0, scrollTop - scrollY) / 100 : 0,
//                 }}
//             >
//                 <svg
//                     xmlns='http://www.w3.org/2000/svg'
//                     fill='none'
//                     viewBox='0 0 24 24'
//                     strokeWidth={1.5}
//                     stroke='currentColor'
//                     className='size-9'
//                 >
//                     <path strokeLinecap='round' strokeLinejoin='round' d='m4.5 18.75 7.5-7.5 7.5 7.5' />
//                     <path strokeLinecap='round' strokeLinejoin='round' d='m4.5 12.75 7.5-7.5 7.5 7.5' />
//                 </svg>
//             </div>
//             <AnimatedBackground scrollY={0} theme={theme} />
//         </div>
//     )
// }

// export default App
