import React from 'react'

const ChatBubble = ({ alignment = 'left', children }) => {
    const alignmentClasses =
        alignment === 'left'
            ? 'mr-auto rounded-tr-xl rounded-bl-xl rounded-br-xl'
            : 'ml-auto rounded-tl-xl rounded-bl-xl rounded-br-xl'

    return (
        <div className={`flex ${alignment === 'left' ? 'justify-start' : 'justify-end'} w-full mb-4`}>
            <div className={`inline-block max-w-[70%] p-4 bg-gray-200 dark:bg-gray-700 ${alignmentClasses}`}>
                {children}
            </div>
        </div>
    )
}

const TypingIndicator = () => {
    return (
        <div className='flex items-center space-x-1'>
            <div className='w-2 h-2 bg-gray-400 rounded-full animate-bounce' style={{ animationDelay: '0ms' }}></div>
            <div className='w-2 h-2 bg-gray-400 rounded-full animate-bounce' style={{ animationDelay: '150ms' }}></div>
            <div className='w-2 h-2 bg-gray-400 rounded-full animate-bounce' style={{ animationDelay: '300ms' }}></div>
        </div>
    )
}

export { ChatBubble, TypingIndicator }
