import React, { useState, useEffect, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import SearchBar from './SearchBar'
import { ChatBubble, TypingIndicator } from './ChatBubble'
import useMediaQuery from '../useMediaQuery'

const Header = ({ isKeyboardVisible, onChatted, toggleTheme, setSearchTerm, isScrolled }) => {
    const headerHeight = 64 // height of the header when scrolled, in pixels

    const [submittedQuestion, setSubmittedQuestion] = useState('')
    const [isTypingResponse, setIsTypingResponse] = useState(false)
    const [answer, setAnswer] = useState('')

    useEffect(() => {
        document.body.style.setProperty('--header-height', `${isScrolled ? headerHeight : 100}vh`)
        setSubmittedQuestion('')
        setIsTypingResponse(false)
        setAnswer('')
    }, [isScrolled])

    const isMdScreen = useMediaQuery('(min-width: 768px)')

    const submitQuestion = async (text) => {
        setSubmittedQuestion(text)
        setAnswer('')
        setIsTypingResponse(true)
        setTimeout(() => {
            setIsTypingResponse(false)
            setAnswer(
                "Looks like Jeremy is very popular this week, I don't have any more LLM credits to answer your question. Please scroll down and take a look at his resume."
            )
        }, 2000)
    }

    return (
        <header
            className={`fixed top-0 left-0 w-full transition-all duration-500 ease-in-out z-50 print:hidden
                ${
                    isScrolled
                        ? 'h-28 md:h-16 bg-opacity-70 backdrop-blur-md backdrop-saturate-150 bg-white dark:bg-opacity-70 dark:backdrop-blur-md dark:backdrop-saturate-150 dark:bg-gray-800 shadow-md py-14 md:py-2'
                        : 'h-screen bg-transparent'
                }`}
        >
            <div
                className={`container mx-auto px-4 h-full flex items-center transition-all duration-500 ease-in-out
                    ${isScrolled ? 'justify-between max-w-7xl mx-auto py-3' : 'flex-col justify-center'}
                    `}
            >
                {isScrolled ? (
                    <>
                        {isMdScreen ? (
                            <>
                                <div className='flex items-center space-x-4 flex-shrink-0'>
                                    <h1 className='font-bold text-2xl text-gray-800 dark:text-gray-300'>
                                        <a href='mailto:jurksztowicz@gmail.com'>Jeremy Jurksztowicz</a>
                                    </h1>
                                </div>

                                <div className='flex-grow mx-4 max-w-2xl mt-7'>
                                    <SearchBar
                                        onSearch={(term) => setSearchTerm(term)}
                                        placeholderText='Filter...'
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='size-5'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z'
                                                />
                                            </svg>
                                        }
                                    />
                                </div>
                                <button
                                    onClick={toggleTheme}
                                    className='bg-gray-200 dark:bg-gray-700 dark:text-gray-300 p-2 rounded focus:outline-none flex-shrink-0 transition-all hover:scale-110'
                                >
                                    🌙 / ☀️
                                </button>
                            </>
                        ) : (
                            <div className='flex flex-col w-full gap-2'>
                                <div className='flex flex-row w-full justify-between mx-auto'>
                                    <div className='flex items-center space-x-4 flex-shrink-0'>
                                        <h1 className='font-bold text-2xl text-gray-800 dark:text-gray-300'>
                                            <a href='mailto:jurksztowicz@gmail.com'>Jeremy Jurksztowicz</a>
                                        </h1>
                                    </div>
                                    <button
                                        onClick={toggleTheme}
                                        className='bg-gray-200 dark:bg-gray-700 dark:text-gray-300 p-2 rounded focus:outline-none flex-shrink-0 transition-all hover:scale-110'
                                    >
                                        🌙 / ☀️
                                    </button>
                                </div>

                                <div className='flex-grow mx-0'>
                                    <SearchBar
                                        onSearch={(term) => setSearchTerm(term)}
                                        placeholderText='Filter...'
                                        icon={
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='size-5'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z'
                                                />
                                            </svg>
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div
                            className={`flex flex-col items-center max-w-3xl w-full text-gray-800 dark:text-gray-300 ${
                                isKeyboardVisible && !isScrolled ? '-mt-64' : ''
                            }`}
                        >
                            <h1
                                className={`font-bold m-8 text-center ${
                                    submittedQuestion ? 'text-4xl w-full sm:-ml-36 md:-ml-72' : 'text-5xl md:text-6xl '
                                }  transition-all duration-1000 ease-in-out`}
                            >
                                <a href='mailto:jurksztowicz@gmail.com'>Jeremy Jurksztowicz</a>
                            </h1>
                            {isMdScreen ? (
                                <>
                                    <div className='w-full px-10'>
                                        <AnimatePresence>
                                            {submittedQuestion && (
                                                <motion.div
                                                    key='question'
                                                    initial={{ opacity: 0, height: 0 }}
                                                    animate={{ opacity: 1, height: 'auto' }}
                                                    exit={{ opacity: 0, height: 0 }}
                                                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                                                    className='overflow-hidden'
                                                >
                                                    <ChatBubble alignment='right'>{submittedQuestion}</ChatBubble>
                                                </motion.div>
                                            )}
                                            {isTypingResponse && (
                                                <motion.div
                                                    key='typing'
                                                    initial={{ opacity: 0, height: 0 }}
                                                    animate={{ opacity: 1, height: 'auto' }}
                                                    exit={{ opacity: 0, height: 0 }}
                                                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                                                    className='overflow-hidden'
                                                >
                                                    <ChatBubble alignment='left'>
                                                        <TypingIndicator />
                                                    </ChatBubble>
                                                </motion.div>
                                            )}
                                            {answer && (
                                                <motion.div
                                                    key='answer'
                                                    initial={{ opacity: 0, height: 0 }}
                                                    animate={{ opacity: 1, height: 'auto' }}
                                                    exit={{ opacity: 0, height: 0 }}
                                                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                                                    className='overflow-hidden'
                                                >
                                                    <ChatBubble alignment='left'>{answer}</ChatBubble>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </div>
                                    <div className='w-full px-10'>
                                        <SearchBar
                                            onSubmit={submitQuestion}
                                            onLoseFocus={() => onChatted()}
                                            placeholderText='Ask me about Jeremy...'
                                            icon={
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    strokeWidth={1.5}
                                                    stroke='currentColor'
                                                    className='size-5'
                                                >
                                                    <path
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        d='M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z'
                                                    />
                                                </svg>
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className='w-full px-10 text-center text-xs font-bold font-mono'>
                                    BEST VIEWED ON DESKTOP
                                </div>
                            )}
                        </div>
                        {!isKeyboardVisible && (
                            <button
                                onClick={() =>
                                    window.scrollTo({
                                        top: isMdScreen ? 500 : 475,
                                        behavior: 'smooth',
                                    })
                                }
                            >
                                <div className='fixed bottom-8 left-1/2 -ml-4 text-gray-800 dark:text-gray-300 animate-bounce'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        strokeWidth={1.5}
                                        stroke='currentColor'
                                        className='size-9'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            d='m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5'
                                        />
                                    </svg>
                                </div>
                            </button>
                        )}
                        <button
                            onClick={toggleTheme}
                            className='absolute right-4 bg-gray-200 dark:bg-gray-700 dark:text-gray-300 p-2 rounded focus:outline-none transition-all hover:scale-110'
                            style={{ top: '11.5px' }}
                        >
                            🌙 / ☀️
                        </button>
                    </>
                )}
            </div>
        </header>
    )
}

export default Header
