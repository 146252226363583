import React, { useRef, useEffect, useState, forwardRef, useMemo } from 'react'
import innerText from 'react-innertext'
import { motion, AnimatePresence } from 'framer-motion'

const ListItem = motion(forwardRef(({ children }, ref) => <li ref={ref}>{children}</li>))

const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const HighlightedText = ({ text, searchTerm }) => {
    if (!searchTerm || searchTerm.length < 3) return text

    const escapedSearchTerm = escapeRegExp(searchTerm)
    const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'gi'))

    return (
        <span>
            {parts.map((part, index) =>
                part.toLowerCase() === searchTerm.toLowerCase() ? (
                    <span key={index} className='bg-yellow-200 dark:bg-yellow-700'>
                        {part}
                    </span>
                ) : (
                    part
                )
            )}
        </span>
    )
}

const Section = forwardRef(({ section }, ref) => {
    return (
        <motion.section
            ref={ref}
            id={section.id}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className='overflow-hidden'
        >
            <h2 className='text-2xl font-semibold mb-4'>{section.title}</h2>
            <ul className='list-disc ml-5 space-y-2'>
                <AnimatePresence initial={false}>
                    {section.items.map((item, index) => (
                        <ListItem
                            key={item.key || index}
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2, ease: 'easeInOut' }}
                        >
                            {item}
                        </ListItem>
                    ))}
                    <div className='p-4' />
                </AnimatePresence>
            </ul>
        </motion.section>
    )
})

function Content({ className, searchTerm, contentRef, setActiveSection, isPrinting }) {
    const sectionRefs = useRef({})

    const Skills = ({ category, skills }) => (
        <div className='flex flex-wrap gap-1'>
            <span className='bg-green-600 text-gray-100 dark:bg-green-800 dark:text-gray-300 px-2 py-1 rounded mr-1'>
                <HighlightedText text={category} searchTerm={searchTerm} />
            </span>
            {skills.map((skill) => (
                <span key={skill} className='bg-gray-200 dark:bg-gray-700 px-2 py-1 rounded'>
                    <HighlightedText text={skill} searchTerm={searchTerm} />
                </span>
            ))}
        </div>
    )

    const sections = useMemo(() => {
        return [
            {
                id: 'services',
                title: 'Services',
                items: [
                    <div className='font-bold'>Front-end dashboards, data visualizations, UX</div>,
                    <div className='font-bold'>Full-stack rapid application development</div>,
                    <div className='font-bold'>AI system design including LLM tools, agent orchestration, and RAG</div>,
                    <div className='font-bold'>API design for data lakes, and data streams</div>,
                    <div className='font-bold'>High-performance ETL pipelines for analysis and ML applications</div>,
                    <div className='font-bold'>
                        Consulting and development for compliance with strict regulatory regimes
                    </div>,
                ],
            },
            {
                id: 'about',
                title: 'About Me',
                items: [
                    <div key='summary'>A veteran software architect, data engineer, and developer</div>,
                    <div key='ai'>Excels in operationalizing AI systems, with full-stack expertise</div>,
                    <div key='healthcare'>
                        Delivers compliant solutions in highly regulated healthcare environments
                    </div>,
                    <div key='rapidly'>Rapidly delivers high-quality software solutions</div>,
                ],
            },
            {
                id: 'skills',
                title: 'Skills',
                items: [
                    <Skills
                        key='frontend'
                        category={'Frontend'}
                        searchTerm={searchTerm}
                        skills={[
                            'JavaScript',
                            'TypeScript',
                            'HTML',
                            "PWA's",
                            'UX',
                            'React',
                            'Preact',
                            'NextJS',
                            'Angular',
                            'VanillaJS',
                            'WebWorkers',
                            'WebAssembly',
                            'Emscripten',
                            'CSS',
                            'TailwindCSS',
                            'Bootstrap',
                            'MUI5',
                            'LeafletJS',
                            'PlotlyJS',
                            'OAuth',
                        ]}
                    />,
                    <Skills
                        key='backend'
                        category={'Backend'}
                        searchTerm={searchTerm}
                        skills={[
                            'Node',
                            'Python',
                            'FastAPI',
                            'Postgres',
                            'SQLAlchemy',
                            'Alembic',
                            'ElasticSearch',
                            'Websockets',
                            'Streaming',
                        ]}
                    />,
                    <Skills
                        key='AI'
                        category={'AI'}
                        searchTerm={searchTerm}
                        skills={[
                            'OpenAI',
                            'Anthropic',
                            'Vertex',
                            'LiteLLM',
                            'Agents',
                            'Autogen',
                            'Langchain',
                            'CrewAI',
                            'RAG',
                            'Assistants',
                        ]}
                    />,
                    <Skills
                        key='cloud'
                        category={'Cloud'}
                        searchTerm={searchTerm}
                        skills={[
                            'Docker',
                            'Kubernetes',
                            'AWS',
                            'Azure',
                            'Google Cloud',
                            'DigitalOcean',
                            'FireBase',
                            'SupaBase',
                            'PocketBase',
                        ]}
                    />,
                    <Skills
                        key='datasci'
                        category={'Data Engineering'}
                        searchTerm={searchTerm}
                        skills={['Numpy', 'Pandas', 'Jupyter', 'Matplotlib', 'Plotly']}
                    />,
                    <Skills
                        key='design'
                        category={'Architecture'}
                        searchTerm={searchTerm}
                        skills={[
                            'Microservces',
                            'Systems',
                            'Distributed',
                            'Realtime',
                            'Performance',
                            'Scalability',
                            'Multithreaded',
                            'TDD',
                        ]}
                    />,
                    <Skills
                        key='system'
                        category={'Other'}
                        searchTerm={searchTerm}
                        skills={[
                            'Algorithms',
                            'DSP',
                            'C',
                            'C++',
                            'Go',
                            'Lua',
                            'Swift',
                            'Objective-C',
                            'Hedera',
                            'Linux',
                            'MacOS',
                            'Embedded',
                            'GitHub',
                            'GitLab',
                        ]}
                    />,
                ],
            },
            {
                id: 'experience',
                title: 'Experience',
                items: [
                    <div key='klick-health-applied-science'>
                        <div className='flex justify-between items-center w-full'>
                            <div>
                                <h3 className='text-lg font-bold'>Klick Health</h3>
                                <span className='text-sm'>Applied Science Consultant</span>
                            </div>
                            <span className='text-sm'>
                                <u>March 2022 - Now</u>
                            </span>
                        </div>

                        <br />
                        <ul>
                            <li>
                                Supporting researchers in applying scientific findings to problems in the healthcare
                                space.
                                <ul>
                                    <li>Developing software to support research projects</li>
                                    <li>ETL data engineering for ML applications</li>
                                    <li>Creating data visualizations for research findings</li>
                                    <li>Supporting the development of new research tools</li>
                                </ul>
                            </li>
                        </ul>
                        <div className='p-6' />
                    </div>,
                    <div key='recerca'>
                        <div className='flex justify-between items-center w-full'>
                            <div>
                                <h3 className='text-lg font-bold'>Recerca</h3>
                                <span className='text-sm'>Founder and Engineering Lead</span>
                            </div>
                            <span className='text-sm'>
                                <u>October 2021 - September 2022</u>
                            </span>
                        </div>

                        <br />
                        <ul>
                            <li>
                                Developed an innovative platform for crowdsourcing scientific research subjects.
                                <ul>
                                    <li>Developed a multi-platform application for iOS, Android, and Web</li>
                                    <li>Designed a scalable backend for managing research subjects</li>
                                    <li>Integrated novel DLT technologies for securing data</li>
                                </ul>
                            </li>
                        </ul>
                        <div className='p-6' />
                    </div>,
                    <div key='klick-health-technical-architect'>
                        <div className='flex justify-between items-center w-full'>
                            <div>
                                <h3 className='text-lg font-bold'>Klick Health</h3>
                                <span className='text-sm'>Technical Architect</span>
                            </div>
                            <span className='text-sm'>
                                <u>May 2019 - March 2022</u>
                            </span>
                        </div>

                        <br />
                        <ul>
                            <li>
                                Designed and implemented software solutions for internal and external clients.
                                <ul>
                                    <li>
                                        Support researchers in collecting and transforming data for use in analysis and
                                        experiments
                                    </li>
                                    <li>
                                        Design data pipelines for use in clinical trials and proof-of-concept in silico
                                        experiments
                                    </li>
                                    <li>
                                        Oversee external partners in architecting and developing consumer-facing digital
                                        health interventions
                                    </li>
                                    <li>
                                        Adhere to FDA 21 CFR part 11 regulatory oversight in developing medical devices
                                        for clinical trials and consumer applications
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div className='p-6' />
                    </div>,

                    <div key='center-for-digital-therapeutics-lead'>
                        <div className='flex justify-between items-center w-full'>
                            <div>
                                <h3 className='text-lg font-bold'>Center For Digital Therapeutics</h3>
                                <span className='text-sm'>iOS Team Lead</span>
                            </div>
                            <span className='text-sm'>
                                <u>December 2017 - May 2019</u>
                            </span>
                        </div>
                        <br />
                        <ul>
                            <li>
                                Led development of novel digital health interventions for managing chronic diseases such
                                as COPD and type 2 diabetes.
                                <ul>
                                    <li>
                                        Worked with human factors engineers to meticulously document use cases, prevent
                                        injury, and maximize intervention benefit.
                                    </li>
                                    <li>
                                        Collaborated with clinicians, researchers, and designers to execute experiments
                                        and achieve clinical goals
                                    </li>
                                    <li>
                                        Developed novel UX strategies that addressed human factors concerns for both
                                        clinicians and patients
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div className='p-6' />
                    </div>,

                    <div key='center-for-digital-therapeutics-senior'>
                        <div className='flex justify-between items-center w-full'>
                            <div>
                                <h3 className='text-lg font-bold'>Center For Digital Therapeutics</h3>
                                <span className='text-sm'>Senior Developer</span>
                            </div>
                            <span className='text-sm'>
                                <u>December 2016 - December 2017</u>
                            </span>
                        </div>
                        <br />
                        <ul>
                            <li>
                                Architected iOS applications for the management of chronic diseases.
                                <ul>
                                    <li>
                                        Used the latest iOS features to deliver a seamless user experience for patients
                                    </li>
                                    <li>Integrated with custom medical sensors for real-time data collection</li>
                                    <li>
                                        Adhered to ISO 13485 medical device development standards to ensure patient
                                        safety and product auditability
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div className='p-6' />
                    </div>,

                    <div key='the-score'>
                        <div className='flex justify-between items-center w-full'>
                            <div>
                                <h3 className='text-lg font-bold'>theScore</h3>
                                <span className='text-sm'>iOS Developer</span>
                            </div>
                            <span className='text-sm'>
                                <u>December 2014 - August 2016</u>
                            </span>
                        </div>
                        <br />
                        <ul>
                            <li>
                                Maintained and enhanced theScore, a mobile app delivering sports news and statistics to
                                millions of unique users over hundreds of millions of monthly sessions.
                                <ul>
                                    <li>
                                        Implemented user login with in-house and Facebook authentication flows,
                                        migrating 4 years of legacy token schemes{' '}
                                        <i>without disruption to existing users</i>
                                    </li>
                                    <li>
                                        Collaborated with back-end developers to optimize data delivery via REST API
                                    </li>
                                    <li>
                                        Translated design documents, including screenshots, wireframes, and user
                                        stories, into completed application components
                                    </li>
                                    <li>
                                        Independently implemented efficiency improvements, including pre-fetching and
                                        cacheing
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div className='p-6' />
                    </div>,

                    <div key='invivo'>
                        <div className='flex justify-between items-center w-full'>
                            <div>
                                <h3 className='text-lg font-bold'>Invivo</h3>
                                <span className='text-sm'>interactive Media Developer</span>
                            </div>
                            <span className='text-sm'>
                                <u>March 2012 - December 2014</u>
                            </span>
                        </div>
                        <br />
                        <ul>
                            <li>
                                Created and maintained a wide variety of applications for use on mobile, web, and
                                desktop platforms. Rapidly created beautiful and performant applications with customized
                                branding. Independently solved a variety of complex technical challenges in realms as
                                diverse as big data collection, computer vision, and physical systems simulation.
                                <ul>
                                    <li>
                                        Engineered FDA class 1 devices for use by doctors and patients around the world
                                    </li>
                                    <li>
                                        Developed an asynchronous multiplexer to stream data in real-time to a Fortune
                                        50 enterprises CMS system
                                    </li>
                                    <li>
                                        Connected multiple heterogenous systems with Python scripts, reducing multi-hour
                                        manual workflows into automated processes taking mere minutes
                                    </li>
                                    <li>Translated wireframes and design assets into completed applications</li>
                                </ul>
                            </li>
                        </ul>
                    </div>,
                ],
            },
            {
                id: 'contact',
                title: 'Contact',
                items: [
                    <div key='email'>
                        <a href='mailto:jurksztowicz@gmail.com'>jurksztowicz@gmail.com</a>
                    </div>,
                    // <div className='print:hidden'>
                    //     <a href='https://www.linkedin.com/in/jeremy-jurksztowicz-9b164591/'>LinkedIn</a>
                    // </div>,
                ],
            },
        ]
    }, [searchTerm])

    const filteredSections = sections.map((section) => ({
        ...section,
        items: section.items.filter((item) => {
            if (searchTerm.length < 3) {
                return true
            }

            if (item.props.skills) {
                if (item.props.category.toLowerCase().includes(searchTerm.toLowerCase())) {
                    return true
                }
                const lowerCaseSkills = item.props.skills.map((s) => s.toLowerCase())
                return lowerCaseSkills.some((s) => s.includes(searchTerm.toLowerCase()))
            } else {
                return innerText(item).toLowerCase().includes(searchTerm.toLowerCase())
            }
        }),
    }))

    // useEffect(() => {
    //     const options = {
    //         root: contentRef.current,
    //         rootMargin: '0px',
    //         threshold: 1.0,
    //     }
    //     const observer = new IntersectionObserver((entries) => {
    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //                 setActiveSection(entry.target.id)
    //             }
    //         })
    //     }, options)
    //     sections.forEach((section) => {
    //         const element = sectionRefs.current[section.id]
    //         if (element) {
    //             observer.observe(element)
    //         }
    //     })
    //     const elements = { ...sectionRefs.current }
    //     return () => {
    //         sections.forEach((section) => {
    //             const element = elements[section.id]
    //             if (element) {
    //                 observer.unobserve(element)
    //             }
    //         })
    //     }
    // }, [sections, setActiveSection, contentRef])

    return (
        <main ref={contentRef} className={`p-2 md:p-6 flex-1 text-gray-700 dark:text-gray-300 ${className}`}>
            <div className='text-5xl mb-6 hidden print:block'>Jeremy Jurksztowicz</div>
            <AnimatePresence initial={false}>
                {filteredSections.map(
                    (section) =>
                        section.items.length > 0 && (
                            <Section
                                key={section.id}
                                section={section}
                                ref={(el) => (sectionRefs.current[section.id] = el)}
                            />
                        )
                )}
            </AnimatePresence>
            {!isPrinting && <div className='h-screen'></div>}
        </main>
    )
}

export default Content
