import React, { useRef, useEffect, useState } from 'react'
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { DotScreenShader } from 'three/examples/jsm/shaders/DotScreenShader'
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader'
import { HalftonePass } from 'three/examples/jsm/postprocessing/HalftonePass'
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass'

extend({ OrbitControls, EffectComposer, RenderPass, ShaderPass, OutputPass })

function Background({ minDistance = 75, particleCount = 500, animationSpeed = 0.02, lineColor, particleColor }) {
    const groupRef = useRef()
    const pointCloudRef = useRef()
    const linesMeshRef = useRef()
    const particlesRef = useRef()
    const [isInitialized, setIsInitialized] = useState(false)

    const maxParticleCount = 1000
    const r = 800
    const rHalf = r / 2

    const particlesData = useRef([])

    const [effectController, setEffectController] = useState({
        showDots: true,
        showLines: true,
        minDistance: minDistance,
        limitConnections: false,
        maxConnections: 50,
        particleCount: particleCount,
    })

    useEffect(() => {
        setEffectController({
            showDots: true,
            showLines: true,
            minDistance: minDistance,
            limitConnections: false,
            maxConnections: 50,
            particleCount: particleCount,
        })
    }, [minDistance, particleCount])

    const lineMaterial = useRef()

    useEffect(() => {
        lineMaterial.current = new THREE.ShaderMaterial({
            vertexShader: `
                attribute float alpha;
                varying float vAlpha;
                void main() {
                    vAlpha = alpha;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform vec3 color;
                varying float vAlpha;
                void main() {
                    gl_FragColor = vec4(color, vAlpha);
                }
            `,
            uniforms: {
                color: { value: new THREE.Color(lineColor) },
            },
            transparent: true,
            blending: THREE.AdditiveBlending,
        })
    }, [lineColor, particleColor])

    useEffect(() => {
        const particlePositions = new Float32Array(maxParticleCount * 3)

        for (let i = 0; i < maxParticleCount; i++) {
            const x = Math.random() * r - r / 2
            const y = Math.random() * r - r / 2
            const z = Math.random() * r - r / 2

            particlePositions[i * 3] = x
            particlePositions[i * 3 + 1] = y
            particlePositions[i * 3 + 2] = z

            particlesData.current.push({
                velocity: new THREE.Vector3(-1 + Math.random() * 2, -1 + Math.random() * 2, -1 + Math.random() * 2),
                numConnections: 0,
            })
        }

        if (particlesRef.current) {
            particlesRef.current.setAttribute(
                'position',
                new THREE.BufferAttribute(particlePositions, 3).setUsage(THREE.DynamicDrawUsage)
            )
            particlesRef.current.setDrawRange(0, effectController.particleCount)
        }

        if (linesMeshRef.current && linesMeshRef.current.geometry) {
            const positions = new Float32Array(maxParticleCount * maxParticleCount * 3)
            const alphas = new Float32Array(maxParticleCount * maxParticleCount)

            linesMeshRef.current.geometry.setAttribute(
                'position',
                new THREE.BufferAttribute(positions, 3).setUsage(THREE.DynamicDrawUsage)
            )
            linesMeshRef.current.geometry.setAttribute(
                'alpha',
                new THREE.BufferAttribute(alphas, 1).setUsage(THREE.DynamicDrawUsage)
            )
            linesMeshRef.current.geometry.setDrawRange(0, 0)
        }

        setIsInitialized(true)
    }, [])

    useFrame(() => {
        if (!isInitialized || !linesMeshRef.current || !linesMeshRef.current.geometry || !particlesRef.current) return

        const positions = linesMeshRef.current.geometry.attributes.position.array
        const alphas = linesMeshRef.current.geometry.attributes.alpha.array
        let vertexpos = 0
        let alphapos = 0
        let numConnected = 0

        for (let i = 0; i < effectController.particleCount; i++) particlesData.current[i].numConnections = 0

        for (let i = 0; i < effectController.particleCount; i++) {
            const particleData = particlesData.current[i]
            const particlePositions = particlesRef.current.attributes.position.array

            particlePositions[i * 3] += particleData.velocity.x * animationSpeed
            particlePositions[i * 3 + 1] += particleData.velocity.y * animationSpeed
            particlePositions[i * 3 + 2] += particleData.velocity.z * animationSpeed

            if (particlePositions[i * 3 + 1] < -rHalf || particlePositions[i * 3 + 1] > rHalf)
                particleData.velocity.y = -particleData.velocity.y

            if (particlePositions[i * 3] < -rHalf || particlePositions[i * 3] > rHalf)
                particleData.velocity.x = -particleData.velocity.x

            if (particlePositions[i * 3 + 2] < -rHalf || particlePositions[i * 3 + 2] > rHalf)
                particleData.velocity.z = -particleData.velocity.z

            if (effectController.limitConnections && particleData.numConnections >= effectController.maxConnections)
                continue

            for (let j = i + 1; j < effectController.particleCount; j++) {
                const particleDataB = particlesData.current[j]
                if (
                    effectController.limitConnections &&
                    particleDataB.numConnections >= effectController.maxConnections
                )
                    continue

                const dx = particlePositions[i * 3] - particlePositions[j * 3]
                const dy = particlePositions[i * 3 + 1] - particlePositions[j * 3 + 1]
                const dz = particlePositions[i * 3 + 2] - particlePositions[j * 3 + 2]
                const dist = Math.sqrt(dx * dx + dy * dy + dz * dz)

                if (dist < effectController.minDistance) {
                    particleData.numConnections++
                    particleDataB.numConnections++

                    const alpha = 1.0 - dist / effectController.minDistance

                    positions[vertexpos++] = particlePositions[i * 3]
                    positions[vertexpos++] = particlePositions[i * 3 + 1]
                    positions[vertexpos++] = particlePositions[i * 3 + 2]

                    positions[vertexpos++] = particlePositions[j * 3]
                    positions[vertexpos++] = particlePositions[j * 3 + 1]
                    positions[vertexpos++] = particlePositions[j * 3 + 2]

                    alphas[alphapos++] = alpha
                    alphas[alphapos++] = alpha

                    numConnected++
                }
            }
        }

        linesMeshRef.current.geometry.setDrawRange(0, numConnected * 2)
        linesMeshRef.current.geometry.attributes.position.needsUpdate = true
        linesMeshRef.current.geometry.attributes.alpha.needsUpdate = true

        particlesRef.current.attributes.position.needsUpdate = true

        groupRef.current.rotation.y += 0.001
    })

    useEffect(() => {
        if (lineMaterial.current) {
            lineMaterial.current.uniforms.color.value.setHex(lineColor)
        }
    }, [lineColor])

    return (
        <group ref={groupRef}>
            <points ref={pointCloudRef}>
                <bufferGeometry ref={particlesRef} />
                <pointsMaterial
                    color={particleColor}
                    size={3}
                    blending={THREE.AdditiveBlending}
                    transparent
                    sizeAttenuation={false}
                />
            </points>
            <lineSegments ref={linesMeshRef}>
                <bufferGeometry />
                <shaderMaterial attach='material' args={[lineMaterial.current]} />
            </lineSegments>
        </group>
    )
}

function Effects() {
    const { gl, scene, camera, size } = useThree()
    const composer = useRef()

    useEffect(() => {
        composer.current = new EffectComposer(gl)
        composer.current.addPass(new RenderPass(scene, camera))

        // const params = {
        //     shape: 1,
        //     radius: 4,
        //     rotateR: Math.PI / 12,
        //     rotateB: (Math.PI / 12) * 2,
        //     rotateG: (Math.PI / 12) * 3,
        //     scatter: 0,
        //     blending: 1,
        //     blendingMode: 1,
        //     greyscale: false,
        //     disable: false,
        // }
        // const halftonePass = new HalftonePass(window.innerWidth, window.innerHeight, params)
        // composer.current.addPass(halftonePass)

        const effect3 = new OutputPass()
        composer.current.addPass(effect3)
    }, [])

    useEffect(() => {
        composer.current.setSize(size.width, size.height)
    }, [size])

    useFrame(() => {
        composer.current.render()
    }, 1)

    return null
}

function AnimatedBackground({ scrollY, theme }) {
    const backgroundRef = useRef(null)
    const lastScrollY = useRef(scrollY)

    // useEffect(() => {
    //     let animationFrameId
    //     const animate = () => {
    //         if (backgroundRef.current) {
    //             const currentScrollY = Math.max(-scrollY, -686)
    //             if (currentScrollY !== lastScrollY.current) {
    //                 backgroundRef.current.style.transform = `translateY(${currentScrollY}px)`
    //                 lastScrollY.current = currentScrollY
    //             }
    //         }
    //         animationFrameId = requestAnimationFrame(animate)
    //     }
    //     animate()
    //     return () => {
    //         if (animationFrameId) {
    //             cancelAnimationFrame(animationFrameId)
    //         }
    //     }
    // }, [scrollY])

    const defaultColor = 0xffffff

    return (
        <div
            ref={backgroundRef}
            className='bg-gray-100 dark:bg-gray-900 h-screen'
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                zIndex: -1,
                willChange: 'transform',
            }}
        >
            <Canvas camera={{ position: [0, 0, 10], fov: 75 }} style={{ opacity: 0.05 }}>
                <Background minDistance={400} lineColor={defaultColor} particleColor={defaultColor} />
                {/* {Math.min(Math.max(120, scrollY / 2), 240)} /> */}
                {/* <Effects /> */}
            </Canvas>
        </div>
    )
}

export default AnimatedBackground
