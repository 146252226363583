import React, { useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import './SearchBar.css'

const SearchBar = ({
    onSearch,
    onLoseFocus,
    onSubmit,
    icon = null,
    placeholderText = 'Search...',
    maxCharLimit = 256,
}) => {
    const [inputValue, setInputValue] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const [error, setError] = useState('')
    const [warning, setWarning] = useState('')
    const inputRef = useRef(null)

    const handleInputChange = (event) => {
        const newValue = event.target.value
        if (newValue.length <= maxCharLimit) {
            setInputValue(newValue)
            setError('')
        } else {
            setError(`Character limit of ${maxCharLimit} exceeded`)
            setWarning('')
        }
    }

    const clearInput = () => {
        setInputValue('')
        setError('')
        setWarning('')
        inputRef.current.blur()
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && onSubmit) {
            if (event.shiftKey) {
                // Shift+Enter: add a newline
                setInputValue((prev) => prev)
            } else {
                // Enter: submit if callback exists and no error
                event.preventDefault()
                if (!error) {
                    onSubmit(inputValue)
                    clearInput()
                }
            }
        } else if (event.key === 'Escape') {
            clearInput()
        }
    }

    const adjustTextareaHeight = () => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto'
            const lineHeight = 24
            const minHeight = lineHeight
            const maxHeight = 4 * lineHeight + 12
            const newHeight = Math.max(minHeight, Math.min(inputRef.current.scrollHeight, maxHeight))
            inputRef.current.style.height = `${newHeight}px`
        }
    }

    useEffect(() => {
        if (onSearch) {
            onSearch(inputValue)
        }
        adjustTextareaHeight()

        // Check for warning
        const remainingChars = maxCharLimit - inputValue.length
        const warningThreshold = Math.floor(maxCharLimit * 0.2)
        if (remainingChars <= warningThreshold && remainingChars >= 0) {
            setWarning(`${remainingChars} characters remaining`)
        } else {
            setWarning('')
        }
    }, [inputValue, maxCharLimit])

    useEffect(() => {
        const handleGlobalKeyDown = (event) => {
            const activeElement = document.activeElement
            const isTypingInInput =
                activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA' ||
                activeElement.isContentEditable

            if (event.key === 'Escape') {
                if (inputRef.current) {
                    if (!isTypingInInput || activeElement === inputRef.current) {
                        clearInput()
                        inputRef.current.blur()
                        event.preventDefault()
                    }
                }
            } else if (
                event.key.length === 1 &&
                !event.ctrlKey &&
                !event.metaKey &&
                !event.altKey &&
                !isTypingInInput
            ) {
                if (inputRef.current) {
                    inputRef.current.focus()
                    setInputValue((prevTerm) => prevTerm + event.key)
                    event.preventDefault()
                    setTimeout(adjustTextareaHeight, 0)
                }
            }
        }

        document.addEventListener('keydown', handleGlobalKeyDown)

        return () => {
            document.removeEventListener('keydown', handleGlobalKeyDown)
        }
    }, [])

    useEffect(() => {
        adjustTextareaHeight()
    }, [])

    return (
        <div>
            <div className='search-container w-full rounded dark:text-gray-300 mb-1'>
                <i
                    className={`text-gray-400 icon left absolute top-2.5 ${
                        isFocused && inputValue.length > 0 ? 'focused text-gray-800 dark:text-gray-300' : ''
                    }`}
                >
                    {icon}
                </i>
                {onSubmit ? (
                    <textarea
                        className={`p-2 ${
                            icon && 'pl-9'
                        } bg-gray-200 dark:bg-gray-700 w-full resize-none overflow-y-auto rounded ${
                            error ? 'border-red-500' : ''
                        }`}
                        value={inputValue}
                        onChange={handleInputChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => {
                            setIsFocused(false)
                            onLoseFocus && onLoseFocus()
                        }}
                        onKeyDown={handleKeyDown}
                        placeholder={placeholderText}
                        ref={inputRef}
                        rows={1}
                        style={{
                            minHeight: '24px',
                            maxHeight: '108px',
                            lineHeight: '24px',
                        }}
                    />
                ) : (
                    <input
                        className={`p-2 ${icon && 'pl-9'} bg-gray-200 dark:bg-gray-700 ${
                            error ? 'border-red-500' : ''
                        }`}
                        type='text'
                        value={inputValue}
                        onChange={handleInputChange}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onKeyDown={(e) => e.key === 'Escape' && clearInput()}
                        placeholder={placeholderText}
                        ref={inputRef}
                    />
                )}
                {inputValue && (
                    <button onClick={clearInput} className='icon right absolute top-2.5'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='size-5'
                        >
                            <path strokeLinecap='round' strokeLinejoin='round' d='M6 18 18 6M6 6l12 12' />
                        </svg>
                    </button>
                )}
            </div>
            <AnimatePresence>
                {error && (
                    <motion.div
                        key='error'
                        className='ml-1 text-red-500 text-sm'
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: '24px' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                        {error}
                    </motion.div>
                )}
                {warning && (
                    <motion.div
                        key='warning'
                        className='ml-1 text-yellow-500 text-sm'
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: '24px' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                        {warning}
                    </motion.div>
                )}
                {!warning && !error && (
                    <motion.div
                        key='spacer'
                        className='ml-1 text-yellow-500 text-sm'
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: '24px' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                        &nbsp;
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default SearchBar
